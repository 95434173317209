export const Archives = [
  {
    id: 1,
    clotheName: "",
    year: "2021",
    cover:
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_4.jpg",
    images: [
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_4.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_5.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_6.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_7.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_8.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_9.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_10.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2021/Pic_11.jpg",
    ],
  },
  {
    id: 2,
    clotheName: "",
    year: "2022",
    cover:
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_4.jpg",
    images: [
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_4.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_5.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_6.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_7.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_8.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_9.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_10.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_11.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_12.jpg",
      "https://image.demiidesign.com/api/Image/Get/Collection_2022/Pic_13.jpg",
    ],
  },
  {
    id: 3,
    clotheName: "",
    year: "2022-Winter",
    cover:
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_11.jpg",
    images: [
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_4.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_5.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_6.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_7.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_8.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_9.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_10.jpg",
      "https://image.demiidesign.com/api/Image/Get/Winter_Collection_2022/Pic_11.jpg",
    ],
  },
];