import React, { useEffect, useState } from "react";
import { Archives } from "../../data/archive";
import { FullScreen } from "../../assets/Icons/DemiIcons";
import Lightbox from "react-image-lightbox";
import { ArchiveNavigation } from "../../components/common/ArchiveNavigation";

const WinterYear2022 = () => {
     const [selectedImage, setSelectedImage] = useState();
     const [photoIndex, setPhotoIndex] = useState();
     const filteredArchives = Archives.filter(
       (archive) => archive.year === "2022-Winter"
     );
     const images =
       filteredArchives.length > 0 ? filteredArchives[0].images : [];

     useEffect(() => {
       window.scroll(0, 0);
     }, []);
     useEffect(() => {
       const body = document.querySelector("body");
       if (selectedImage) {
         body.style.overflow = "hidden"; // Disable scrolling
       } else {
         body.style.overflow = ""; // Enable scrolling
       }

       return () => {
         // Cleanup: Re-enable scrolling when component unmounts
         body.style.overflow = "";
       };
     }, [selectedImage]);
  return (
    <div className="flex flex-col items-center p-8">
      <div className="flex space-x-4 mb-8">
        <ArchiveNavigation/>
      </div>
      <h1 className="uppercase font-light text-demiblack">
        Winter 2022 <span className="font-bold"> Collection</span>
      </h1>
      <div className="flex flex-wrap justify-center">
        {Archives.map((archive) => (
          <>
            {archive.year === "2022-Winter" &&
              archive.images.map((image, index) => (
                <div
                  key={index}
                  className="m-2 max-w-xs relative group cursor-pointer"
                  onClick={() => {
                    setSelectedImage(image);
                    setPhotoIndex(index);
                  }}
                >
                  <img
                    src={image}
                    alt=""
                    loading="lazy"
                    className="transition object-cover h-full w-full duration-500 ease-in-out transform"
                  />
                  <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 flex items-center justify-between p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                    <span className="text-white text-lg">{`Collection Year | ${archive.year}`}</span>
                    <span className="text-white text-sm">
                      <FullScreen />
                    </span>
                  </div>
                </div>
              ))}
          </>
        ))}
        {selectedImage && (
          <Lightbox
            key={selectedImage}
            imageTitle={"Collection For " + Archives[0].year}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setSelectedImage(null)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default WinterYear2022;
