import React, { useEffect, useState } from "react";
import OrderModal from "../components/modals/order_modal";
import DemiButton from "../components/features/DemiButton";
import { PhoneIcon, UserIcon } from "../assets/Icons/DemiIcons";
import DemiInput from "../components/features/DemiInput";
import axios from "axios";
import { DotLoading } from "../components/loading/Loading";
import { ErrorState, SuccesfulStates } from "../components/states/States";


const Order = ({ isModalOpen, handleModal, productData, order }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);


  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
    window.scroll(0,0)
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);
  
  const handleSuccess = () => {
    setIsSuccess(!isSuccess);
  };
  const handleError = () => {
    setIsError(!isError);
  };
const sendMessage = async () => {

  // const validateName = (name) => {
  //   const regex = /^[a-zA-Z\s]*$/;
  //   return regex.test(name);
  // };

if (name=== "") {
  setNameError(true);
} else if (phoneNumber=== "") {
  setNameError(false)
  setPhoneError(true)
}
else {
  const botToken = "6492328088:AAH0bNW4cRB3p7CQmwC0t2wweOS3YZ21yR8";
  const chatId = "366105512";
  const formData = new FormData();
  const formattedMessage = `
    | NEW ORDER HAS ARRIVED! |
    Product Code: ${productData.itemCode}
    Product Ordered : ${productData.itemName}
    Customer Name : ${name}
    Customer's Phone Number : ${phoneNumber}
    Total Amount of Items : ${totalAmount}
    ${Object.keys(order)
      .map((key) => {
        const { selectedSize, amount } = order[key];
        return `Size : ${selectedSize}, Amount : ${amount}`;
      })
      .join("\n")}
  `;

  formData.append("chat_id", chatId);
  formData.append("photo", productData.image[0]);
  formData.append("caption", formattedMessage);

  setIsLoading(true);
  setNameError(false);
  setPhoneError(false)

  try {
    const response = await axios.post(
      `https://api.telegram.org/bot${botToken}/sendPhoto`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Message sent:", response.data);
    handleSuccess();
    setIsLoading(false);

    // Reset the name and phone number
    setName("");
    setPhoneNumber("");
  } catch (error) {
    console.error("Error sending message:", error);
    setIsError(!isError);
    setIsLoading(false);
  }
}

};

  const handleName = (event) => {
    setName(event.target.value);
    console.log(name);
  };
  const handlePhone = (event) => {
    setPhoneNumber(event.target.value.replace(/\D/g, ''));
  };

  const totalAmount = order.reduce((total, order) => total + order.amount, 0);
  return (
    isModalOpen && (
      <OrderModal>
        <SuccesfulStates
          isSuccess={isSuccess}
          handleSuccess={handleSuccess}
          handleModal={handleModal}
        />
        <ErrorState
          Error={isError}
          handleError={handleError}
          handleModal={handleModal}
        />
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 w-full h-64 md:h-[28rem]">
            <img
              className="object-cover w-full h-full"
              src={productData.image[0]}
              alt=""
            />
          </div>
          <div className="md:w-2/3 w-full  p-10 flex flex-col items-center md:mx-5 space-y-5">
            <h1 className="text-base font-bold text-center md:text-left">
              <br />
              Fill in the required information to order*
            </h1>
            <div className="w-full space-y-2">
              <DemiInput
                onChange={handleName}
                placeHolder="Enter Full Name"
                icon={<UserIcon className="text-slate-500" />}
              />
              {nameError && (
                <p className="text-sm text-red-600 pl-1">Please provide your name!</p>
              )}
              <DemiInput
                isPhone={true}
                onChange={(e) => handlePhone(e)}
                placeHolder="Enter Phone-Number"
                icon={<PhoneIcon className="text-slate-500" />}
              />
              {phoneError && (
                <p className="text-sm text-red-600 pl-1">Please provide your phone number!</p>
              )}
            </div>
            <div className="w-full space-y-2">
              <DemiButton
                SubmitButton={isLoading ? false : true}
                outlined={isLoading ? true : false}
                onClick={isLoading ? null : sendMessage}
                isLoading={isLoading}
                text={
                  isLoading ? (
                    <div className="p-2">
                      <DotLoading />
                    </div>
                  ) : (
                    "Submit Order"
                  )
                }
              />
              <DemiButton
                outlined={true}
                onClick={handleModal}
                text={"Cancel Order"}
              />
            </div>
          </div>
        </div>
      </OrderModal>
    )
  );
};

export default Order;
