import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageSlider from '../components/features/ImageSlider';
import OrderContainer from '../containers/OrderContainer';
import YouMayAlsoLike from '../components/features/YouMayAlsoLike';
import { ClotheData } from '../data/Items';

const ClothDetail = () => {
  const { clothID } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const selectedProduct = ClotheData.find((e) => e.id === parseInt(clothID));
  return (
    <div className="font-demiiRegular">
      <div className="flex flex-col items-start mt-0 md:mt-7 px-0 md:px-5 md:flex-row">
        <div className="w-full md:w-1/2">
          <ImageSlider images={selectedProduct.image} />
        </div>
        <OrderContainer product={selectedProduct} />
      </div>
      <YouMayAlsoLike
        selectedProduct={selectedProduct}
        suggestedProducts={ClotheData}
      />
    </div>
  );
};

export default ClothDetail;
