import React from 'react'

const DemiInput = ({ placeHolder, icon,onChange,isPhone }) => {
    return isPhone? (
        <div className='w-full border p-3 text-sm border-neutral-400 flex gap-2 items-center'>
           <input type='number' required  maxLength={12} placeholder={placeHolder} onChange={onChange}
                className='w-full outline-none border-r-0 font-light' />
            {icon}
        </div>
    ):<div>
         <div className='w-full border p-3 text-sm border-neutral-400 flex items-center'>
           <input type='text' required placeholder={placeHolder} onChange={onChange}
                className='w-full outline-none border-r-0 font-light' />
            {icon}
            <div></div>  
        </div>
    </div>
}

export default DemiInput