import {React, useEffect} from "react";
// import G1 from "../assets/statics/G1.jpg";
// import G2 from "../assets/statics/G2.jpg";
// import G3 from "../assets/statics/G3.jpg";
// import G4 from "../assets/statics/G4.jpg";
import { Archives } from "../data/archive";
import { Link } from "react-router-dom";

const Collection = () => {

   useEffect(() => {
     window.scroll(0, 0);
   }, []);
  
  return (
    <div className="font-demiiSemiBold flex w-full md:px-28 p-10 md:flex-row flex-col justify-center h-screen items-center md:my-0 my-20">
      <div className="md:-rotate-90 flex flex-col items-center w-36 justify-center">
        <h1 className="md:text-6xl text-4xl text-slate-800 font-light md:my-0 my-12">
          COLLECTION
        </h1>
        <p className="w-full font-light"></p>
      </div>
      <div className="grid md:grid-cols-2  md:grid-rows-2 grid-cols-2 grid-rows-3 gap-4 md:gap-9 w-full h-full m-4">
        <Link
          to={"/archive/2021"}
          className="grid-item md:row-span-2 md:col-span-1 col-span-2 bg-slate-100 "
        >
          <img
            className="object-cover w-full h-full"
            src={Archives[0].cover}
            alt=""
          />
          <div className="overlay-text w-full flex text-center md:text-sm text-sm flex-col items-center">
            <h1>OUR GABI COLLECTION FROM 2022</h1>
            <h1 className="text-sm">Go to Archive for more</h1>
          </div>
        </Link>
        <Link
          to={"/archive/2022"}
          className="grid-item md:w-80 w-full bg-slate-100"
        >
          <img
            className="object-cover md:w-80 w-full h-full"
            src={Archives[1].cover}
            alt=""
          />
          <div className="overlay-text md:w-80 w-full flex flex-col text-center md:text-sm text-sm items-center">
            <h1>OUR COLLECTION FROM 2022</h1>
            <h1 className="text-sm">Goto Archive for more</h1>
          </div>
        </Link>
        <Link
          to={"/archive/2022-winter"}
          className="grid-item  md:col-start-2 md:w-80 md:row-start-2 col-start-2 w-full row-start-2 bg-slate-100 "
        >
          <img
            className="object-cover md:w-80 w-full h-full"
            src={Archives[2].cover}
            alt=""
          />
          <div className="overlay-text w-full md:w-80 flex flex-col items-center text-center md:text-sm text-sm">
            <h1>OUR COLLECTION FROM 2022 WINTER</h1>
            <h1 className="text-sm">Goto Archive for more</h1>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Collection;
