import React from "react";
// import F1 from "../assets/statics/F1.jpg";
// import F2 from "../assets/statics/F2.jpg";
// import F3 from "../assets/statics/F3.jpg";
// import F4 from "../assets/statics/F4.jpg";
import Lion from "../assets/statics/Removed BG.png";

const HomeContainer = () => {
  return (
    <div className="bg-demiblack pb- w-full flex-col md:flex-row h-auto justify-center flex  overflow-hidden text-slate-100 items-center font-light">
      <div className="w-full p-12 md:w-1/2 m-4 md:m-20 flex flex-col gap-4 justify-center">
        <h1 className="text-4xl md:text-5xl">
          <span className="font-semibold">DEMII</span>
          <div className="w-16 md:w-32 h-0.5 my-2 md:my-5 bg-slate-100" />
          <h1 className="font-light">DESIGN</h1>
        </h1>
        <p className="font-demiiLight text-base md:text-lg">
          Celebrating Ethiopian artistry, empowering local
          weavers, promoting handwoven fabrics, preserving cultural heritage,
          supporting communities sustainably, and showcasing Ethiopian fabric
          beauty globally.
        </p>
      </div>
      <div className="w-full md:w-1/2  md:h-full h-full flex items-end justify-end">
        <img className="grayscale object-cover w-full h-full" src={Lion} alt="" />
        {/* <div className="grid w-full md:w-[80%] gap-2 md:gap-9">
          <div className="col-span-2 row-span-3">
            <img className="object-cover w-full h-full" src={F1} alt="" />
          </div>
          <div className="col-span-2 col-start-1 row-start-4">
            <img className="object-cover w-full h-full" src={F2} alt="" />
          </div>
          <div className="col-span-2 row-span-3 col-start-3 row-start-2">
            <img className="object-cover w-full h-full" src={F4} alt="" />
          </div>
          <div className="col-span-2 col-start-3 row-start-1">
            <img className="object-cover w-full h-full" src={F3} alt="" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomeContainer;
