import React from "react";
import DemiCard from "./DemiCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/css/scrollbar";

const YouMayAlsoLike = ({ selectedProduct, suggestedProducts }) => {
  const filteredSuggestedProducts = suggestedProducts.filter(
    (product) =>
      product.type === selectedProduct.type && product.id !== selectedProduct.id
  );
  const swiperBreakpoints = {
    // When window width is >= 320px
    320: {
      slidesPerView: 1.1,
      spaceBetween: 8,
      centeredSlides: true,
    },
    // When window width is >= 640px
    640: {
      slidesPerView: 2,
      spaceBetween: 30,
      centeredSlides: true,
    },
    // When window width is >= 768px
    768: {
      slidesPerView: 2.5,
      spaceBetween: 50,
    },
    // When window width is >= 1024px
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 2,
    },
  };
  return (
    <div className="container w-full mx-auto mt-8">
      <h2 className="text-2xl font-semibold mb-4 ml-4">You May Also Like</h2>
      <div className="relative">
        <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          freeMode={true}
          mousewheel={true}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          scrollbar={{
            draggable: true,
            hide: true,
          }}
          breakpoints={swiperBreakpoints}
          className="mySwiper2 w-full h-full"
        >
          {filteredSuggestedProducts.map((product) => (
            <SwiperSlide key={product.id}>
              <DemiCard
                name={product.itemName}
                image={product.image[0]}
                price={product.price}
                size={product.productSize.join(",")}
                id={product.id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default YouMayAlsoLike;
