export const ClotheData = [
  {
    id: 1,
    itemName: "Feven Jacket",
    itemCode: "D-JAU-V1",
    type: "Jacket",
    itemStyle: "Bomber Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Feven_Jacket/Feven_Jacket.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "Cotton - 80% Leather synthetic 20%",
    modelFit: {
      height: "Model is 170cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
  },
  {
    id: 2,
    itemName: "Kucheza Jacket",
    itemCode: "D-JAU-V2",
    type: "Jacket",
    itemStyle: "Bomber Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Kucheza_Jacket/Kucheza_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Kucheza_Jacket/Kucheza_Jacket_2.jpg",
    ],
    itemDescription:
      "Unisex oldschool Leather Sleeve Varsity Jacket: Fusion of Tradition and Modernity, Africa-Inspired Patterns!",
    composition: "Cotton - 80% Leather synthetic 20%",
    modelFit: {
      height: "Model is 185cm",
      size: "Model wears size XXL",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  {
    id: 3,
    itemName: "Frita Top",
    itemCode: "D-TOPF-V1",
    type: "Top",
    itemStyle: "Offshoulder crop top with flare sleeves",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Frita_Top/Frita_Top_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Frita_Top/Frita_Top_2.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 170cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
  },
  {
    id: 4,
    itemName: "Sefed Jacket",
    itemCode: "D-JAU-V3",
    type: "Jacket",
    itemStyle: "Bomber Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Sefed_Jacket/Sefed_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Sefed_Jacket/Sefed_Jacket_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Sefed_Jacket/Sefed_Jacket_3.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  {
    id: 5,
    itemName: "Mariposa Dress",
    itemCode: "D-DRF-V1",
    type: "Dress",
    itemStyle: "Dress",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Mariposa_dress/Meriposa_dress_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Mariposa_dress/Meriposa_dress_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Mariposa_dress/Meriposa_dress_3.jpg",
    ],
    itemDescription:
      "A tunic T-shirt dress with embroidery on both the front and back side, has raglan sleeves and two side pockets",
    composition: "100% Silk/Satin",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 5000,
  },
  {
    id: 6,
    itemName: "Meron Top",
    itemCode: "D-TOPF-V4",
    type: "Top",
    itemStyle: "Top",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Meron_Top/Meron_Top.jpg",
    ],
    itemDescription:
      "A corset with a tight fit, the straps are tied on the shoulders",
    composition: "100% Silk/Satin",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 1500,
  },
  {
    id: 7,
    itemName: "Meron Pants",
    itemCode: "D-TRF-V1",
    type: "Pants",
    itemStyle: "Trouser",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Meron_Pants/Meron_Pants.jpg",
    ],
    itemDescription:
      "A wide trousers with loose fit and two side pockets on the sides",
    composition: "100% Silk/Satin",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 2000,
  },
  {
    id: 8,
    itemName: "Hermela Crop Coat",
    itemCode: "D-COF-V1",
    type: "Coat",
    itemStyle: "Coat",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Hermela_Crop_Coat/Hermela_Crop_Coat_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Hermela_Crop_Coat/Hermela_Crop_Coat_2.jpg",
    ],
    itemDescription:
      "A crop female formal coat with different embroidery on it",
    composition: "100% DK fabric",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3500,
  },
  {
    id: 9,
    itemName: "Hermela Mini Skirt",
    itemCode: "D-DRF-V2",
    type: "Skirt",
    itemStyle: "Skirt",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Hermela_Crop_Coat/Hermela_Crop_Coat_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Hermela_Crop_Coat/Hermela_Crop_Coat_.jpg",
    ],
    itemDescription: "Mini Skirt with embroidery on it",
    composition: "100% DK fabric",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 2000,
  },
  {
    id: 10,
    itemName: "Betty Coat",
    itemCode: "D-COF-V2",
    type: "Coat",
    itemStyle: "Coat",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Betty_Coat/Betty_Coat_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Betty_Coat/Betty_Coat_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Betty_Coat/Betty_Coat_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Betty_Coat/Betty_Coat_4.jpg",
    ],
    itemDescription: "Coat with bishop sleeves, tied with a belt",
    composition: "60% DK 40% Silk/Satin",
    modelFit: {
      height: "Model is 170cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3000,
  },
  {
    id: 11,
    itemName: "Kelly Hoodie",
    itemCode: "D-SWU-V1",
    type: "Hoodie",
    itemStyle: "Hoodie",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Kelly_Hoodie/Kelly_Hoodie_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Kelly_Hoodie/Kelly_Hoodie_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Kelly_Hoodie/Kelly_Hoodie_3.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  {
    id: 12,
    itemName: "Elsa Jacket",
    itemCode: "D-JAU-V4",
    type: "Jacket",
    itemStyle: "Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Elsa_Jacket/Elsa_Jacket_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Elsa_Jacket/Elsa_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Elsa_Jacket/Elsa_Jacket_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Elsa_Jacket/Elsa_Jacket_4.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  {
    id: 13,
    itemName: "Shaku Jacket",
    itemCode: "D-JAU-V5",
    type: "Jacket",
    itemStyle: "Bomber Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Shaku_Jacket/Shaku_Jacket_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Shaku_Jacket/Shaku_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Shaku_Jacket/Shaku_Jacket_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Shaku_Jacket/Shaku_Jacket_4.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  {
    id: 14,
    itemName: "Eyuel Sweater",
    itemCode: "D-SWU-V2",
    type: "Sweater",
    itemStyle: "Sweater",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Eyuel_Sweater/Eyuel_Sweaters_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Eyuel_Sweater/Eyuel_Sweaters_2.jpg",
    ],
    itemDescription: "Sweater with chest pockets",
    composition: "60% cotton 40% leather synthetic",
    modelFit: {
      height: "Model is 180cm",
      size: "Model wears size L",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3000,
  },
  {
    id: 15,
    itemName: "Feker T-shirt",
    itemCode: "D-TSU-V1",
    type: "T-shirt",
    itemStyle: "T-shirt",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_4.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_5.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_6.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_7.jpg",
      "https://image.demiidesign.com/api/Image/Get/Feker_Tshirt/Feker_Tshirt_8.jpg",
    ],
    itemDescription: "T-shirt with Chest pockets",
    composition: "60% cotton 40% leather synthetic",
    modelFit: {
      height: "Model is 180cm",
      size: "Model wears size L",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 1500,
  },
  {
    id: 16,
    itemName: "Alex Sweater",
    itemCode: "D-SWU-V3",
    type: "Sweater",
    itemStyle: "Sweater",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Alex_Sweater/Alex_Sweater.jpg",
    ],
    itemDescription:
      "A sweater made in one of the most known and used Ethiopian fabric called Fetel or Gabi in fusion with different other fabric materials",
    composition: "80% cotton 20% leather synthetic",
    modelFit: {
      height: "Model is 170cm",
      size: "Model wears size L",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 2500,
  },
  {
    id: 17,
    itemName: "Bemnet Top",
    itemCode: "D-TOPF-V2",
    type: "Top",
    itemStyle: "Top",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Bemnet_Top/Bemnet_Top_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Bemnet_Top/Bemnet_Top_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Bemnet_Top/Bemnet_Top_3.jpg",
    ],
    itemDescription:
      "A top made in one of the most known and used Ethiopian fabric called Fetel or Gabi",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 165cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 2500,
  },
  {
    id: 18,
    itemName: "Mack Overcoat",
    itemCode: "D-COF-V3",
    type: "Overcoat",
    itemStyle: "Overcoat",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Mack_Over_Coat/Mack_Over_Coat_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Mack_Over_Coat/Mack_Over_Coat_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Mack_Over_Coat/Mack_Over_Coat_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Mack_Over_Coat/Mack_Over_Coat_4.jpg",
    ],
    itemDescription:
      "A top made in one of the most known and used Ethiopian fabric called Fetel or Gabi",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 165cm",
      size: "Model wears size S",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 2500,
  },
  {
    id: 19,
    itemName: "Becky Jacket",
    itemCode: "D-JAU-V6",
    type: "Jacket",
    itemStyle: "Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Becky_Jacket/Becky_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Becky_Jacket/Becky_Jacket_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Becky_Jacket/Becky_Jacket_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Becky_Jacket/Becky_Jacket_4.jpg",
    ],
    itemDescription:
      "A denim jacket or trucker jacket, is a type of casual outerwear made of denim fabric. 98-99% of the raw material is denim",
    composition: "98-99% denim",
    modelFit: {
      height: "Model is L",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3500,
  },
  {
    id: 20,
    itemName: "Hemma Jacket",
    itemCode: "D-JAU-V7",
    type: "Jacket",
    itemStyle: "Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Hemma_Jacket/Hemma_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Hemma_Jacket/Hemma_Jacket_2.jpg",
    ],
    itemDescription:
      "A denim jacket or trucker jacket, is a type of casual outerwear made of denim fabric. 98-99% of the raw material is denim",
    composition: "98-99% denim",
    modelFit: {
      height: "Model is S",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 4000,
  },
  {
    id: 21,
    itemName: "Saron Pants",
    itemCode: "D-TRF-V2",
    type: "Pants",
    itemStyle: "Pants",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Saron_Pants/Saron_Pants_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Saron_Pants/Saron_Pants_2.jpg",
      "https://image.demiidesign.com/api/Image/Get/Saron_Pants/Saron_Pants_3.jpg",
      "https://image.demiidesign.com/api/Image/Get/Saron_Pants/Saron_Pants_4.jpg",
    ],
    itemDescription:
      "Wide pants that are made for comfortable movement and giving a relaxed feeling, has two cargo pockets on its side",
    composition: "100% cotton",
    modelFit: {
      height: "Model is M",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 1500,
  },
  {
    id: 22,
    itemName: "Hemma Pants",
    itemCode: "D-TRF-V3",
    type: "Pants",
    itemStyle: "Pants",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Hemma_Pants/Hemma_Pants_4.jpg",
      "https://image.demiidesign.com/api/Image/Get/Hemma_Pants/Hemma_Pants_3.jpg",
    ],
    itemDescription:
      "A denim jacket or trucker jacket, is a type of casual outerwear made of denim fabric. 98-99% of the raw material is denim",
    composition: "98-99% denim",
    modelFit: {
      height: "Model is M",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3000,
  },
  {
    id: 23,
    itemName: "Kisanet Jacket",
    itemCode: "D-JAU-V4",
    type: "Jacket",
    itemStyle: "Bomber Jacket",
    image: [
      "https://image.demiidesign.com/api/Image/Get/Kisanet_Jacket/Kisanet_Jacket_1.jpg",
      "https://image.demiidesign.com/api/Image/Get/Kisanet_Jacket/Kisanet_Jacket_2.jpg",
    ],
    itemDescription:
      "Ethiopian Patterns, a Timeless Tribute: Inspired by the rich cultural tapestry of Ethiopia, our jacket showcases unique patterns that beautifully capture the spirit of this ancient land.",
    composition: "100% cotton",
    modelFit: {
      height: "Model is 155cm",
      size: "Model wears size M",
    },
    productSize: ["S", "M", "L", "XL"],
    price: 3200,
    featured: true,
  },
  // ... (other clothing items)
];
