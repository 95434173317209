import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Thumbs, Autoplay } from "swiper/modules";

const ImageSlider = ({ images }) => {
  const thumbsSwiper = useRef(null);
  const [activeIndex,setActiveIndex] = useState(0);

  const handleSlideChange=(swiper)=>{
    setActiveIndex(swiper.realIndex)
    // thumbsSwiper.current.slideTo(swiper.activeIndex)
  }

  return (
    <div className="md:w-[88%] w-full">
      <div className="">
        <div className="md:h-[28rem] h-[22rem] relative">
          <Swiper
            loop={true}
            autoplay={true}
            onSlideChange={handleSlideChange}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper.current }}
            modules={[FreeMode, Thumbs, Autoplay]}
            className="mySwiper2 w-full h-full bg-slate-200"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-full h-full object-cover"
                  src={image}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => {
          thumbsSwiper.current = swiper;
        }}
        spaceBetween={10}
        loop={true}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper mt-4"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              className={`${index===activeIndex?'opacity-100':'opacity-70 '} cursor-pointer w-2/3 transition-all duration-100 h-20 rounded-lg object-cover m-4 md:m-0`}
              src={image}
              alt={`Thumbnail ${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const OrderImageSlider = ({ images }) => {
  return (
    <div className="">
          <Swiper
            loop={true}
            autoplay={true}
            spaceBetween={10}
            className="mySwiper2 w-[350px] h-[350px] bg-slate-200 rounded-none md:rounded-lg"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-full h-full rounded-none md:rounded-lg object-cover"
                  src={image}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
  );
};
export default ImageSlider;