import React, { useState } from "react";
import { AddIcon, SendMailIcon } from "../assets/Icons/DemiIcons";
import DemiButton from "../components/features/DemiButton";
import Order from '../containers/Order';

const OrderContainer = ({ product }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectionError,setError]=useState('')
  const [orders, setOrders] = useState([
    {
      amount: 1,
      selectedSize: "",
    },
  ]);

  const handleModalOpen = () => {
    let allSizesSelected = true;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].selectedSize === "") {
        allSizesSelected = false;
        break;
      }
    }
    if (allSizesSelected) {
      setError('')
      setModalOpen(!isModalOpen);
    } else {
      setError("* Please select a size for your orders before proceeding. *");
    }
  }

  const handleSizeSelection = (orderIndex, selectedSize) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].selectedSize = selectedSize;
    setOrders(updatedOrders);
  };

const addNewOrder = () => {
  setOrders([
    ...orders,
    { sizes: product.productSize, amount: 1, selectedSize: "" },
  ]);
};
  const removeOrder = (index) => {
    if (orders.length === 1) {
      alert("You must have at least one order.");
      return;
    } else {
      const newOrders = [...orders];
      newOrders.splice(index, 1);
      setOrders(newOrders);
    }
  };
  const incrementOrder = (index) => {
    const newOrders = [...orders];
    newOrders[index].amount++;
    setOrders(newOrders);
  };

  const decrementOrder = (index) => {
    const newOrders = [...orders];
    if (newOrders[index].amount > 1) {
      newOrders[index].amount--;
      setOrders(newOrders);
    }
  };

  const totalAmount = orders.reduce((total, order) => total + order.amount, 0);
  return (
    <>
      <Order
        order={orders}
        isModalOpen={isModalOpen}
        handleModal={handleModalOpen}
        productData={product}
      />
      <div className="w-full px-4 flex-1 flex h-fit flex-col gap-8 text-slate-800 items-start md:w-1/2">
        <div className="space-y-2">
          <h1 className="text-4xl font-bold ">{product.itemName}</h1>
          <h1 className="font-semibold text-gray-800 text-xl">
            {product.price} <span className="font-medium text-sm">ETB</span>
          </h1>
          <h1>Category | {product.itemStyle.toUpperCase()}</h1>
          <h1>Composition | {product.composition}</h1>
          <p className="text-lg font-light">{product.itemDescription}</p>
        </div>
        <div className="w-3/4 h-[0.5px] bg-neutral-300" />
        <ul className="text-neutral-500 list-disc">
          <h1 className="font-bold text-slate-800 text-lg">About the Model</h1>
          <li>{product.modelFit.height.toUpperCase()}</li>
          <li>{product.modelFit.size.toUpperCase()}</li>
        </ul>
        <div className="w-3/4 h-[0.5px] bg-neutral-300" />
        <div className="flex w-3/4 justify-between items-center gap-3">
          <div>
            <h1 className="font-bold text-lg">Order here</h1>
            <h1 className="text-base ">Choose from the available sizes</h1>
          </div>
          <h1 className="text-base font-demiiMedium">
            Total Orders | <span className=" text-base">{totalAmount}</span>
          </h1>
        </div>
        <span className="text-red-600 capitalize">{ selectionError }</span>
        <div
          className={`h-56 w-full space-y-10 ${
            orders.length > 1 &&
            "overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-800 scrollbar-track-neutral-200"
          } bg-slate-100  rounded-md`}
        >
          {orders.map((o, index) => (
            <div>
              <div className="flex gap-5">
                {product.productSize.map((size, sizeIndex) => (
                  <div key={sizeIndex}>
                    <div
                      onClick={() => {
                        handleSizeSelection(index, size);
                      }}
                      className={`${
                        o.selectedSize === size
                          ? "bg-slate-800 text-white"
                          : "bg-neutral-200"
                      } w-10 flex text-sm p-2 mb-8 items-center justify-center text-slate-800 border  hover:bg-slate-800 hover:text-white cursor-pointer`}
                    >
                      {size}
                    </div>
                  </div>
                ))}
                <div className="w-3/4 h-[0.5px] bg-neutral-300" />
              </div>
              <h1 className="text-base ">Amount of orders</h1>
              <div className="flex w-full items-center gap-3 text-3xl">
                <button
                  onClick={() => decrementOrder(index)}
                  className="bg-neutral-200 hover:bg-slate-800 hover:text-neutral-50 duration-150 text-xl text-slate-800 p-3 h-7 w-7 flex items-center justify-center rounded-full"
                >
                  -
                </button>
                <p className="font-serif text-base">{o.amount}</p>
                <button
                  onClick={() => incrementOrder(index)}
                  className="bg-neutral-200 hover:bg-slate-800 hover:text-neutral-50 duration-150 text-xl text-slate-800 p-3 h-5 w-5 flex items-center justify-center rounded-full"
                >
                  +
                </button>
              </div>
              {orders.length > 1 && (
                <button
                  onClick={() => removeOrder(index)}
                  className="border border-slate-800 p-1 px-4 m-1 mt-5 text-sm hover:bg-slate-800 hover:text-neutral-50 transition-all"
                >
                  Delete
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="w-full space-y-4">
          <DemiButton
            onClick={addNewOrder}
            outlined={true}
            text={"Add another order"}
            icon={<AddIcon />}
          />
          <DemiButton
            onClick={handleModalOpen}
            SubmitButton={true}
            outlined={false}
            text={"Send Your Order"}
            icon={<SendMailIcon className="text-white" />}
          />
        </div>
      </div>
    </>
  );
};

export default OrderContainer;
