import React from "react";
import DemiCard from "./DemiCard";

const ProductDisplayComponent = ({ products }) => {
  return (
    <div className="flex w-full items-center justify-center flex-wrap gap-4">
      {products.map((product) => (
        <DemiCard
          key={product.id}
          name={product.itemName}
          image={product.image[0]}
          price={product.price}
          size={product.productSize.join(",")}
          id={product.id}
          className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4" // Adjust the width based on screen size
        />
      ))}
    </div>
  );
};

export default ProductDisplayComponent;
