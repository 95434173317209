import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ClothDetail from "../pages/ClothDetail";
import Story from "../pages/Story";
import Archive from "../pages/Archive";
import Shop from "../pages/Shop";
import Year2021 from "../containers/Archive/2021";
import Year2022 from "../containers/Archive/2022";
import WinterYear2022 from "../containers/Archive/Winter2022";

const DemiiRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" Component={LandingPage} />
      <Route exact path="/clothDetail/:clothID" Component={ClothDetail} />
      <Route path="/story" Component={Story} />
      <Route path="/archive" Component={Archive} />
      <Route path="/shop" Component={Shop} />
      <Route path="/archive/2021" Component={Year2021} />
      <Route path="/archive/2022" Component={Year2022} />
      <Route path="/archive/2022-winter" Component={WinterYear2022} />
    </Routes>
  );
};

export default DemiiRoutes;
