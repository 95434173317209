import { ArchiveNavigation } from '../components/common/ArchiveNavigation';
import React, { useEffect, useState } from "react";
import { Archives } from "../data/archive";
import { FullScreen } from "../assets/Icons/DemiIcons";
import Lightbox from "react-image-lightbox";

const Archive = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [photoIndex, setPhotoIndex] = useState();


  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (selectedImage) {
      body.style.overflow = "hidden"; // Disable scrolling
    } else {
      body.style.overflow = ""; // Enable scrolling
    }
    return () => {
      // Cleanup: Re-enable scrolling when component unmounts
      body.style.overflow = "";
    };
  }, [selectedImage]);

 const allImages = Archives.reduce((acc, archive) => {
   acc.push(...archive.images);
   return acc;
 }, []);
  return (
    <div className="flex flex-col items-center p-8">
      <div className="flex space-x-4 mb-8">
      <ArchiveNavigation />
      </div>
      <h1 className="uppercase font-light text-demiblack">
        All <span className="font-bold"> Archives</span>
      </h1>
      <div className="flex flex-wrap justify-center">
        {Archives.map((archive) => (
          <React.Fragment key={archive.id}>
            {archive.images.map((image, index) => (
              <div
                key={index}
                className="m-2 max-w-xs relative group cursor-pointer"
                onClick={() => {
                  setSelectedImage(image);
                  setPhotoIndex(allImages.indexOf(image));
                }}
              >
                <img
                  src={image}
                  alt=""
                  loading="lazy"
                  className="transition object-cover h-full w-full duration-500 ease-in-out transform"
                />
                <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 flex items-center justify-between p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <span className="text-white text-lg">{`Collection Year | ${archive.year}`}</span>
                  <span className="text-white text-sm">
                    <FullScreen />
                  </span>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
        {selectedImage && (
          <Lightbox
            key={selectedImage}
            imageTitle={"Collection For "}
            mainSrc={selectedImage}
            nextSrc={allImages[(photoIndex + 1) % allImages.length]}
            prevSrc={
              allImages[(photoIndex + allImages.length - 1) % allImages.length]
            }
            onCloseRequest={() => setSelectedImage(null)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + allImages.length - 1) % allImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % allImages.length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default Archive;
