import React from "react";
import { Link, useLocation } from "react-router-dom";

export function ArchiveNavigation() {
  const location = useLocation();
  return (
    <div className="text-center w-screen space-x-2">
      {" "}
      <Link
        to="/archive"
        className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
          location.pathname === "/archive"
            ? "bg-white text-demiblack shadow-md"
            : "bg-demiblack text-white"
        }`}
      >
        All
      </Link>
      <Link
        to="/archive/2022"
        className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
          location.pathname === "/archive/2022"
            ? "bg-white text-demiblack shadow-md"
            : "bg-demiblack text-white"
        }`}
      >
        2022
      </Link>
      <Link
        to="/archive/2022-winter"
        className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
          location.pathname === "/archive/2022-winter"
            ? "bg-white text-demiblack shadow-md"
            : "bg-demiblack text-white"
        }`}
      >
        Winter 2022
      </Link>
      <Link
        to="/archive/2021"
        className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
          location.pathname === "/archive/2021"
            ? "bg-white text-demiblack shadow-md"
            : "bg-demiblack text-white"
        }`}
      >
        2021
      </Link>
    </div>
  );
}
