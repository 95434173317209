import React, { useState } from "react";
import ProductDisplayComponent from "../components/features/ProductDisplayComponent";
import SortingComponent from "../components/features/SortingComponent";
import { Link } from "react-router-dom";
import { ClotheData } from "../data/Items";

const Shop = () => {
  const [items, setItems] = useState(ClotheData);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleSort = (sortOrder) => {
    const sortedItems = [...items].sort((a, b) =>
      sortOrder === "asc" ? a.price - b.price : b.price - a.price
    );
    setItems(sortedItems);
  };

  const handleFilter = (category) => {
    if (category === "all") {
      setItems(ClotheData);
    } else {
      const filteredItems = ClotheData.filter(
        (item) => item.type === category
      );
      setItems(filteredItems);
    }
    setSelectedCategory(category);
  };

  return (
    <div className="w-full flex flex-col items-center p-8 md:p-16 gap-4">
      <h1 className="text-slate-500 text-3xl font-bold">OUR SHOP</h1>
      <div className="w-full h-fit flex gap-5 flex-col md:flex-row justify-between items-center">
        <div className="flex  gap-2 items-center text-slate-400">
          <Link to={"/"} className="hover:text-slate-800 duration-150">
            Home
          </Link>
          <span className="">/</span>
          <Link to={"/shop"} className="hover:text-slate-800 duration-150">
            Shop
          </Link>
        </div>
        <ul className="flex flex-col md:flex-row gap-4 font-light text-base mt-4 md:mt-0">
          <button
            onClick={() => handleFilter("all")}
            className={`md:mr-4 ${
              selectedCategory === "all" ? "selected" : ""
            }`}
          >
            ALL
          </button>
          <button
            onClick={() => handleFilter("Jacket")}
            className={`md:mr-4 ${
              selectedCategory === "Jacket" ? "selected" : ""
            }`}
          >
            JACKETS
          </button>
          <button
            onClick={() => handleFilter("Pants")}
            className={`md:mr-4 ${
              selectedCategory === "Pants" ? "selected" : ""
            }`}
          >
            PANTS
          </button>
          <button
            onClick={() => handleFilter("Dress")}
            className={selectedCategory === "Dress" ? "selected" : ""}
          >
            DRESSES
          </button>
          <button
            onClick={() => handleFilter("Top")}
            className={selectedCategory === "Top" ? "selected" : ""}
          >
            TOP
          </button>
          <button
            onClick={() => handleFilter("Coat")}
            className={selectedCategory === "Coat" ? "selected" : ""}
          >
            COAT
          </button>
          <button
            onClick={() => handleFilter("Skirt")}
            className={selectedCategory === "Skirt" ? "selected" : ""}
          >
            SKIRT
          </button>
          <button
            onClick={() => handleFilter("Hoodie")}
            className={selectedCategory === "Hoodie" ? "selected" : ""}
          >
            HOODIE
          </button>
          <button
            onClick={() => handleFilter("Sweater")}
            className={selectedCategory === "Sweater" ? "selected" : ""}
          >
            SWEATER
          </button>
        </ul>
        <SortingComponent onSort={handleSort} />
      </div>
      <ProductDisplayComponent products={items} />
    </div>
  );
};

export default Shop;
