import React from 'react';
import DemiCard from '../components/features/DemiCard';
import { ViewAll } from '../assets/Icons/DemiIcons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import { ClotheData } from '../data/Items';

const Products = () => {
  const swiperBreakpoints = {
    // When window width is >= 320px
    320: {
      slidesPerView: 1.1,
      spaceBetween: 8,
      centeredSlides: true,
    },
    // When window width is >= 640px
    640: {
      slidesPerView: 2,
      spaceBetween: 30,
      freeMode: true,
    },
    // When window width is >= 768px
    768: {
      slidesPerView: 2.5,
      spaceBetween: 50,
      freeMode: true,
    },
    // When window width is >= 1024px
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 2,
      freeMode: true,
    },
  };

  return (
    <div className="font-demiiSemiBold relative">
      <div className="md:ml-10 m-4 md:m-8 text-xl md:text-4xl flex items-center justify-between">
        <div>
          <h1 className="text-slate-800 font-light">Featured products</h1>
        </div>
        <div className="text-base md:text-xl font-demiiBold flex items-center gap-2 cursor-pointer">
          <ViewAll className="text-slate-600 w-4 md:w-6 h-6" />
          <Link
            to={"/shop"}
            className="text-slate-800 hover:text-slate-950 hover:scale-105 duration-150"
          >
            Show all products
          </Link>
        </div>
      </div>
      <div className="relative">
        <Swiper
          direction={"horizontal"}
          mousewheel={true}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          scrollbar={{
            draggable: true,
            hide: true,
          }}
          breakpoints={swiperBreakpoints}
          className="mySwiper2 w-full h-full"
        >
          {ClotheData.map((product) => (
            <>
              {product.featured && (
                <SwiperSlide className="w-full" key={product.id}>
                  <DemiCard
                    name={product.itemName}
                    image={product.image[0]}
                    price={product.price}
                    size={product.productSize.join(",")}
                    id={product.id}
                  />
                </SwiperSlide>
              )}
            </>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Products;
